import React from "react";
import { window } from "browser-monads";
import { getImage } from "gatsby-plugin-image";
import { graphql, Link, PageProps } from "gatsby";
import { Button, ButtonVariant } from "brass-ui-kit";
import SEO from "components/SEO";
import {
  Hero,
  FaqWidget,
  Resources,
  GetStarted,
  QualitiesList,
} from "components/design-system";

import { Features } from "components/retail";

import pagesMeta from "data/pagesMeta";
import { RETAIL_DATA } from "data/pages";
import { APP_SIGNUP_URL } from "data/config";

const Retail: React.FC<PageProps> = ({ data }) => {
  const {
    allJavascriptFrontmatter: {
      edges: [
        {
          node: { frontmatter: pageData },
        },
      ],
    },
  } = data as any;

  return (
    <>
      <SEO {...pagesMeta.retail} />
      <Hero
        title="Run your retail business on a better current account"
        backgroundImage={pageData.hero.background}
      >
        {window.innerWidth >= 768 && (
          <Button
            as={"a"}
            variant={ButtonVariant.Primary}
            href={APP_SIGNUP_URL}
            target="_blank"
          >
            Get started in minutes
          </Button>
        )}
      </Hero>

      <Features />

      <Resources
        align="center"
        overflow={false}
        title="Always improving to meet your needs"
        description={
          "With a line of products on our roadmap, you can keep doing your best work while we work on the tools and services you need."
        }
        items={pageData.resources}
      />

      <FaqWidget content={pageData.faqContent} />

      <QualitiesList items={RETAIL_DATA.QUALITIES} />

      <GetStarted theme="light" />
    </>
  );
};

export const pageQuery = graphql`
  query RetailPageQuery {
    allJavascriptFrontmatter(
      filter: { frontmatter: { name: { eq: "retail" } } }
    ) {
      edges {
        node {
          frontmatter {
            hero {
              background {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            resources {
              link
              title
              excerpt
              image {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            faqContent {
              answer
              question
            }
          }
        }
      }
    }
  }
`;

export default Retail;
